
ul, ol {
	&.styled-list {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			img, 
			svg {
				max-width: 23px;
			}
        }
	}

	&.center-list {
		li {
			img, 
			svg {
				max-width: 15px;
			}
        }
	}

	&.check-therapist {
		li {
			padding: 1.2rem 2rem 1.2rem 1.2rem;
			margin-bottom: 3rem;
			img {
				max-width: 200px;
				@include media-breakpoint-up(md) {
					min-width: 200px;
				}
				@include media-breakpoint-up(lg) {
					max-width: 230px;
					min-width: 230px;
				}
			}
		}
	}

	&.check-list-steps {
		li {
			padding: 6.5rem 3rem;
			margin-bottom: 2rem;
			border-radius: 10px;
		}
	}
	&.check-list {
		li {
			margin-bottom: 1.5rem;
			@include media-breakpoint-up(xl) {
				margin-bottom: 2.5rem;
			}
		}
	}
}