section {
    &#hero {
        padding: 100px 0 200px;
        @include media-breakpoint-up(lg) {
            padding: 160px 0;
        }
        @include media-breakpoint-up(xml) {
            padding: 200px 0;
        }
       
        .background-image-container {
            background-color: #f5fdff;
            img {
                object-position: right bottom;
                object-fit: contain;
                @include media-breakpoint-up(md) {
                    object-fit: cover;
                    object-position: center bottom;
                }
            }
        }
        h1 {
            @include media-breakpoint-up(xl) {
                min-width: 650px;

                &.ty{
                    min-width: 100%;
                }
            }
        }
    }

    &#section-2 {
        overflow: visible;

        .bg-left-bottom {
            position: absolute;
            max-width: 85px;
            top: 20px;
            left: -30px;
            z-index: -1;
        }
        .bg-white {
            margin-top: -96px;
            border-radius: 15px;
            padding: 2rem;
            @include media-breakpoint-up(lg) {
                margin-top: -70px;
            }
            @include media-breakpoint-up(xl) {
                padding: 3rem 4rem;
            }
            img {
                max-width: 50px;
                @include media-breakpoint-up(xl) {
                    max-width: 55px;
                }
            }
            p {
                max-width: 100%;
                @include media-breakpoint-down(lg) {
                    font-size: 1.6rem;
                }
            }
        }
    }
    &#section-6, &#section-15 {
        .background-image-container img {
            background-color: white;
            object-position: center bottom;
            object-fit: contain;
            @include media-breakpoint-up(xl) {
                object-fit: cover;
            }
        }
    }
    &#section-6 {
        padding-bottom: 33%;

        .bg-left-center {
            max-width: 93px;
            left: -3%;
            top: calc(50% - 75px);
            @include media-breakpoint-up(lg) {
                left: 3%;
            }
        }

        .bg-white {
            border-radius: 15px;
            padding: 3rem 5rem;
            img {
                max-width: 55px;
            }
        }
    }
    &#section-8 {
        .background-image-container img {
            object-position: left center;
            @include media-breakpoint-up(lg) {
                object-position: calc(100% + 200px) center;
            }
            @include media-breakpoint-up(xl) {
                object-position: right center;
            }
        }
    }
    
    &#section-9 {
        .bg-top-right {
            max-width: 93px;
            right: -30px;
            top: -60px;
            z-index: -1;
        }
        .bg-bottom-left {
            max-width: 126px;
            bottom: -30px;
            left: -30px;
            z-index: -1;
        }
    }
    
    &#section-12 {
        .description {
            @include media-breakpoint-up(lg) {
                max-width: 80%;
            }
            @include media-breakpoint-up(xl) {
                max-width: 65%;
            }
        }
    }
    &#section-13 {
        .bg-bottom-left {
           bottom: -40px;
           left: -20px;     
           max-width: 81.5px;
           z-index: -1;
        }
        .bg-bottom-right {
           right: -20px;
           top: -40px;     
           max-width: 126px;
           z-index: -1;
        }
        .map {
            border-radius: 10px;
            @include media-breakpoint-up(lg) {
                border-radius: 20px;
            }
        }
    }
    
    &#section-14 {
        .swiper-slide {
            height: auto;
            .bg-white {
                padding: 3rem 2.5rem;
                border-radius: 30px 0 30px 0;
                box-shadow: 0px 5px 7px 0px rgba(42, 109, 142, 0.1);
                img {
                    max-width: 133.5px;
                }
            }
            p {
                max-width: 100%;
            }
        }
    }

    
    &#section-15 {
        padding-bottom: 45%;
    }
}