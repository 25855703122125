// --------------------
// BUTTON STYLE
//---------------------

.btn,
button,
input[type=submit],
input[type=button] {
    width: 100%;
    border: 0;
    border-radius: 5px;
    margin-bottom: 12px;
    line-height: 1;
    text-transform: capitalize;
    padding: 20px 10px;
    white-space: normal;
    background: $primary;
    color: #fff;
    font-size: 2.3rem;
    font-family: $headings-font;
    transition: background-color .4s ease-in-out;
    outline: none !important;
    @include media-breakpoint-up(md) {
        padding: 30px 10px;
    }
    &:hover,
    &:focus,
    &:active {
        color: #fff;
    }
}

.btn-section {
    p {
        width: 90%;
        margin: 0 auto;
        font-size: 1.45rem;
    }
}