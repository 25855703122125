#steps-block {
    .bg-top-left {
        top: -40px;
        left: -20px;     
        max-width: 81.5px;
        z-index: -1;
    }
    .bg-bottom-left {
        bottom: 0px;
        left: 0px;     
        max-width: 391px;
        z-index: -1;
        width: 21%;
        @include media-breakpoint-up(md) {
            bottom: 40px;
        }
    }
    .step {
        &-number {
            color: #fff;
            position: relative;
            border-radius: 50%;
            background: $color-blue;
            font-family: $headings-font;
            font-size: 2.75rem;
            top: -25px;
            margin-left: -25px;
            width: 50px;
            height: 50px;
            line-height: 50px;
            @include media-breakpoint-up(md) {
                top: -30px;
                margin-left: -30px;
                width: 60px;
                height: 60px;
                line-height: 60px;
            }
            &:before {
              content: '';
              border-radius: 50%;
              position: absolute;
              background-color: rgba(42, 109, 142, 0.1);
              width: 70px;
              height: 70px;
              left: -10px;
              top: -10px;
              @include media-breakpoint-up(md) {
                width: 90px;
                height: 90px;
                left: -15px;
                top: -15px;
              }
            }
        }
    }
}