// --------------------
// Include Fonts Here
// --------------------

@font-face {
    font-family: 'Gilroy-Bold';
    src: url('../../fonts/Gilroy-Bold.woff2') format('woff2'),
        url('../../fonts/Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway-Medium';
    src: url('../../fonts/Raleway-Medium.woff2') format('woff2'),
        url('../../fonts/Raleway-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway-Bold';
    src: url('../../fonts/Raleway-Bold.woff2') format('woff2'),
        url('../../fonts/Raleway-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

$headings-font: 'Gilroy-Bold', sans-serif;
$body-font: 'Raleway-Medium', sans-serif;
$bold-font: 'Raleway-Bold', sans-serif;
// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 50%;  //1rem = 8px
    @include media-breakpoint-up(md) {
        font-size: 56.25%; //1rem = 9px
    }
    @include media-breakpoint-up(lg) {
        font-size: 62.5%; // 1rem = 10px
    }
}

html, body {
    @include font-smoothing('on');
    font-size: 1.9rem;
    line-height: 1.4;
    font-family: $body-font;
}

p {
    margin-bottom: 3rem;
}

// Headline padding
h1, .h1, 
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    line-height: 1.2;
    margin-bottom: 3rem;
    font-family: $headings-font;
    font-weight: bold;
    text-transform: capitalize;
    color: $secondary;
}

h1, .h1 {
    font-size: 3.5rem;
    @include media-breakpoint-up(md) {
        font-size: 4rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 5rem;
    }
    @include media-breakpoint-up(xl) {
        font-size: 5.75rem;
    }
}

h2, .h2 {
    font-size: 3rem;
    @include media-breakpoint-up(md) {
        font-size: 3.5rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 4rem;
    }
}

h3, .h3 {
    font-size: 2.8rem;
    @include media-breakpoint-up(md) {
        font-size: 3rem;
    }
}

h4, .h4 {
    font-size: 2.5rem;
}

h5, .h5 {
    font-size: 2.25rem;
}

h6, .h6 {
    font-size: 1.8rem;
}

strong {
	font-family: $headings-font;
    font-size: 2rem;
}

// Hero Specific Font Sizes
.eyebrow {
    text-transform: uppercase;
    font-size: 1.8rem;
    @include media-breakpoint-up(xl) {
        font-size: 2rem;
    }
    font-family: $bold-font;
    color: $color-blue;
}

.subheadline {

}