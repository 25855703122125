
#table {
	.table-logo {
		border-radius: 5px;
		background: white;
		padding: 1rem 2rem;
		z-index: 1;
		position: relative;
	}
	
	.bg-left-top {
		max-width: 50px;
		left: -5%;
		top: -15%;
		@include media-breakpoint-up(lg) {
			max-width: 93px;
		}
	}
	.bg-right-bottom {
		max-width: 126px;
		bottom: -5%;
		right: -5%;
	}
	.table-wrapper {
		position: relative;
	}
	.table {
		background-color: $white;
		border-radius: 5px;
		tr {
			th,
			td {
				width: 20%;
				padding: 10px;
				vertical-align: middle;
				border-top: none;
				border-bottom: 1px solid #f2f2f2;
				@include media-breakpoint-up(md) {
					padding: 10px;
				}
				img.icon {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					&.icon-check {
						box-shadow: 0 13px 40px 0 rgba(#2a6d8e, .32);
					}
				}
				&.first-column {
					width: 40%;
				}
	
				&.active-cell {
					position: relative;
					background-color: $color-blue;
					border-bottom: 1px solid #39c;
				}
				
			}
	
			&:last-child td {
				&.active-cell:before {
					content: "";
					position: absolute;
					width: 100%;
					background-color: $color-blue;
					left: 0;
					height: 40px;
				}
			}
			&:last-child td.active-cell:before {
				bottom:-30px;
				border-radius: 0 0 10px 10px;
			}
			
			th {
				&.active-cell {
					padding: 5px;
					img {
						max-width: 100%;
					}
					&:before {
						top:-30px;
						border-radius: 10px 10px 0 0;
					}
				}
			}
		}
	}
}