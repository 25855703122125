
// --------------------
// VARIABLES
//---------------------

$headerheight   : 130px;  // Change to approximate header height - adds padding to first section
$header-starting-width: 250px;
$header-scrolled-width: 160px;

// Colors
$body-color		: #2a2a2a;
$primary        : #80be42;
$secondary      : #161c42;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
// $dark:          $gray-800;

$color-blue: #289fd9;
$color-gray: #f4fcfd;
// --------------------
// Container Options
//---------------------

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
);
  
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1140px,
	xml: 1450px,
	xxl: 1600px,
);
