header {
    padding: 15px;
    background-color: transparent;
    transition: background-color 2s ease-in-out;
    &.scrolled {
        box-shadow: -3px -3px 6px 8px rgba(0,0,0,.05);
        background-color: white;
    }
    .view-animation {
        opacity: 1;
    }
}

.header-logo img {
    transition: width 0.2s ease-in-out;
    width: $header-scrolled-width;
    @include media-breakpoint-up(lg) {
        width: $header-starting-width;
    }
    .scrolled & {
        width: $headerheight;
        @include media-breakpoint-up(lg) {
            width: $header-scrolled-width;
        }
    }
}

.header-phone {
    line-height: 1.2;
    text-decoration: none !important;
    color: $secondary;
    .phone-icon {
        img,
        svg {
            width: 30px;
            background-color: #80be42;
            border-radius: 100px;
            padding: 1rem;
            @include media-breakpoint-up(lg) {
                width: 62px;
                padding: 1.8rem;
            }
        }
    }

    span {
        font-size: 1.9rem;
        font-family: $body-font;
    }
    em {
        font-style: normal;
        font-size: 3rem;
        font-family: $headings-font;
    }
}