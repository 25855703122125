form {
    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select {
        width: 100%;
        padding: 20px 15px 20px 50px;
        box-shadow: 0px 20px 75px 0px rgba(42, 109, 142, 0.1);
        border: none;
        border-radius: 5px;
        margin-bottom: 15px;
        font-size: 1.9rem;
        line-height: 1;
        color: $body-color;
        outline: none !important;
        @include media-breakpoint-up(md) {
            padding: 33px 15px 33px 70px;
        }
    }

    textarea {
        height: 210px !important;
    }

    select {
        appearance: none;
    }

    label ,
    .form-group{
        position: relative;
        width: 100%;
        margin-bottom: 0;
        line-height: 1;
        .icon {
            position: absolute;
            top: 20px;
            left: 15px;
            width: 18px;
            height: 25px;
            z-index: 999;

            @include media-breakpoint-up(md) {
                left: 25px;
                top: 34px;
            }
        }
        &.field-1 {
            z-index: 5;
        }
        &.field-2 {
            z-index: 4;
        }
        &.field-3 {
            z-index: 3;
        }
        &.field-4 {
            z-index: 2;
        }
        &.field-5 {
            z-index: 1;
        }
    }

    label.error,
    .field-error {
        display: block;
        margin-bottom: 20px;
        margin-top: -10px;
        color: red;
        font-size: 1.9rem;
    }


    .form-messages {
        text-align: center;
        &.success {
            color: green;
        }
        &.error {
            color: red;
        }
    }
}

::placeholder {
    color: $body-color;
}